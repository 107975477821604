import { Component, Inject, OnDestroy, OnInit/*, PLATFORM_ID*/, Input } from '@angular/core';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';
// import { SportDataService } from 'src/app/sport-data.service';
// import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LocalisationService } from 'src/app/localisation.service';
// import { CookieService } from 'ngx-cookie';
// import { AuthService } from '@auth0/auth0-angular';
import { faAngleRight, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MobileStickyBannerComponent } from '../mobile-sticky-banner/mobile-sticky-banner.component';
import { MobileNavigationComponent } from '../mobile-navigation/mobile-navigation.component';
import { DesktopNavigationComponent } from '../desktop-navigation/desktop-navigation.component';
import { GameBarBlockComponent } from '../game-bar-block/game-bar-block.component';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BlackFridayBannerComponent } from 'src/app/general/black-friday-banner/black-friday-banner.component'
@Component({
    selector: 'app-site-header',
    templateUrl: './site-header.component.html',
    styleUrls: ['./site-header.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        GameBarBlockComponent,
        DesktopNavigationComponent,
        MobileNavigationComponent,
        MobileStickyBannerComponent,
        FontAwesomeModule,
        // BlackFridayBannerComponent,
    ]
})
export class SiteHeaderComponent implements OnInit, OnDestroy {
    dropdownSports: Array<Sport>;
    otherSports: Array<Sport>;
    liveLightVisible = false;
    gameBarSports: Array<string> = null;
    // newsletterBannerVisible = false;
    faAngleRight = faAngleRight;
    faXmark = faXmark;

    bannerVisible: boolean = true;
    
    @Input() fireNewsletterPopUp: () => void;
    @Input() showScore: boolean;
    @Input() showMobileOffers: boolean;

    constructor(
        // private sportDataService: SportDataService,
        // @Inject(DOCUMENT) public document: Document,
		// @Inject(PLATFORM_ID) private platformID: object,
        // public authHelper: AuthHelperService,
        private localisationService: LocalisationService,
    ) {}

    ngOnInit(): void {
        this.dropdownSports = this.localisationService.sportPrioritySort(SPORTS.filter(s => s.active && !s.hideFromMainNav));
        this.otherSports = this.localisationService.sportPrioritySort(SPORTS.filter(s => !s.active && !s.hideFromMainNav));

        this.activateScoreStripBlock();

		// if (typeof document !== "undefined") {
		// 	this.checkForLiveMatches();
		// }

        if (typeof document !== "undefined" && typeof window !== "undefined") {
            // if user has previously subscribed, don't show banner
            // if (!(this.cookieService.hasKey("mtc_id") || (this.cookieService.hasKey("neverShowPopup") && this.cookieService.get("neverShowPopup") === "true"))) {
            //   this.newsletterBannerVisible = true;
            // }
            // this.checkIfuserLoggedIn();
            // if(sessionStorage.getItem('showBanner') !== 'true'){
            //     this.bannerVisible = true;
            // }
            // else {
            //     this.bannerVisible = false;
            // }
        }
    }

    activateScoreStripBlock(): void {
        if (typeof document !== "undefined") {
            // hack to clear out old score strip block
            const blockContainer = document.getElementById("si-external-game-bar");
            if (blockContainer) {
                const newBlockContainer = document.createElement("div");
                newBlockContainer.id = "si-external-game-bar";
                blockContainer.parentNode.replaceChild(newBlockContainer, blockContainer);
            }
            if (typeof window !== "undefined" && typeof document !== "undefined") {
                this.gameBarSports = this.localisationService
                    .sportPrioritySort(SPORTS.filter(s => s.active && s.showInScoreStrip && !s.hideFromMainNav)).map(s => s.code);
            }
        }

    }

    // checkForLiveMatches(): void {
    //     this.sportDataService.anyLiveMatchesOn()
    //         .subscribe((anyLive: boolean) => {
    //             if (anyLive) {
    //                 this.liveLightVisible = true;
    //             } else {
    //                 this.liveLightVisible = false;
    //             }

    //             if (isPlatformBrowser(this.platformID)) {
    //                 setTimeout(() => this.checkForLiveMatches(), 30000);
    //             }

    //         }, _ => {
    //             this.liveLightVisible = false;

    //             if (isPlatformBrowser(this.platformID)) {
    //                 setTimeout(() => this.checkForLiveMatches(), 30000);
    //             }
    //         })
    // }

    // initialGrowth() {
    //     window.analytics.page("with-score-strip");
    // 	window.analytics.ready(() => {
    // 		const gb = new GrowthBook({
    // 			apiHost: "https://gb-api.statsinsider.com.au",
    // 			clientKey: "sdk-TnIPQBEsk01aLkGv",
    // 			enableDevMode: true,
    // 			attributes: {
    // 				id: window.analytics.user().anonymousId()
    // 			},
    // 			trackingCallback: (experiment, result) => {
    // 				window.analytics.track("Experiment Viewed", {
    // 					experimentId: experiment.key,
    // 					variationId: result.key,
    // 				});
    // 			},
    // 		});
    // 		gb.loadFeatures({ autoRefresh: true }).then(() => {
    //             this.inShapedTest = true;
    //             console.log("Loaded features");
    // 				if (gb.isOn("score-strip-recommended")) {
    // 					this.usesShaped = true;
    //                     console.log("on");
    // 				}
    // 				else {
    // 					this.usesShaped = false;
    //                     console.log("off");
    // 				}
    // 		});    
    // 	});
    // }

    // checkIfuserLoggedIn(){
    //     //for testing purposes, set userID to 3994742 in the cookie
    //     // this.cookieService.put("mtc_id", "3994742");
    //     this.auth.isAuthenticated$.subscribe(result=>{
    //         if (result && this.cookieService.get("mtc_id")) {
    //             this.initialGrowth();
    //         }
    //         else {
    //             this.inShapedTest = false;
    //             this.usesShaped = false;
    //         }
    //     });
    // }

    ngOnDestroy(): void {
        // hack to clear out old score strip block
        if (typeof document !== "undefined") {
            const blockContainer = document.getElementById("si-external-game-bar");
            if (blockContainer) {
                blockContainer.remove();
            }
        }
    }

    closeBanner() {
        // sessionStorage.setItem('showBanner', 'true');
        this.bannerVisible = false;
    }

}
