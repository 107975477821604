import { DOCUMENT /*, isPlatformBrowser*/, NgIf, AsyncPipe, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit,PLATFORM_ID} from '@angular/core';
// import { TransferState } from '@angular/platform-browser';
// import { Router } from '@angular/router';
// import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
// import { faChevronDown, faChevronRight, faEllipsisH, faSearch, faTimes, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
// import { compareAsc, parseJSON } from 'date-fns';
import { Observable, forkJoin, map, shareReplay } from 'rxjs';
import { AuthHelperService } from 'src/app/auth-helper.service';
// import { BlogArticle } from 'src/app/blog-article.model';
import { BlogService } from 'src/app/blog.service';
// import { Match } from 'src/app/match.model';
import { MatchService } from 'src/app/match.service';
import { NavigationItem } from 'src/app/navigation-item.model';
// import { SportDataService } from 'src/app/sport-data.service';
import { Sport } from 'src/app/sport.model';
import { DesktopNavigationItemComponent } from '../desktop-navigation-item/desktop-navigation-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-desktop-navigation',
    templateUrl: './desktop-navigation.component.html',
    styleUrls: ['./desktop-navigation.component.scss'],
    standalone: true,
    imports: [NgIf, DesktopNavigationItemComponent, AsyncPipe, FontAwesomeModule]
})
export class DesktopNavigationComponent implements OnInit  {
	
	// searchBarActive = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	// searchText = "";
	// isBrowser: boolean;
	// sportsbooks = [];
    // dfsProviders = [];
    // promoCodes = [];

    faBell = faBell;
    dotVisible:boolean = true;
	// faChevronRight = faChevronRight;
	// faChevronDown = faChevronDown;
	// faEllipsisH = faEllipsisH;
	// faSearch = faSearch;
	// faTimes = faTimes;
	// faExternalLinkAlt = faExternalLink;
	// faCircleUser = faCircleUser;

    states =[
		{ name: 'Arizona', iconLink: 'https://imgix.cosmicjs.com/ec3f30c0-1001-11ee-b1c4-fdbd98303cc6-Arizona.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/arizona" },
		{ name: 'Colorado', iconLink: 'https://imgix.cosmicjs.com/ec401b20-1001-11ee-b1c4-fdbd98303cc6-Colorado.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/colorado" },
		{ name: 'Illinois', iconLink: 'https://imgix.cosmicjs.com/ec409050-1001-11ee-b1c4-fdbd98303cc6-Illinois.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/illinois" }, 
		{ name: 'Indiana', iconLink: 'https://imgix.cosmicjs.com/ec428c20-1001-11ee-b1c4-fdbd98303cc6-Indiana.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/indiana" },
		{ name: 'Kansas', iconLink: 'https://imgix.cosmicjs.com/ec437680-1001-11ee-b1c4-fdbd98303cc6-Kansas.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/kansas" }, 
		{ name: 'Kentucky', iconLink: 'https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/kentucky" },
		{ name: 'Louisiana', iconLink: 'https://imgix.cosmicjs.com/ec460e90-1001-11ee-b1c4-fdbd98303cc6-Louisiana.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/louisiana" },
		{ name: 'Maryland', iconLink: 'https://imgix.cosmicjs.com/ec46d1e0-1001-11ee-b1c4-fdbd98303cc6-Maryland.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/maryland" }, 
		{ name: 'Massachusetts', iconLink: 'https://imgix.cosmicjs.com/ec5d1900-1001-11ee-b1c4-fdbd98303cc6-Massachusetts.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/massachusetts" },
		{ name: 'Michigan', iconLink: 'https://imgix.cosmicjs.com/ec5f3be0-1001-11ee-b1c4-fdbd98303cc6-Michigan.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/michigan" }, 
		{ name: 'New Jersey', iconLink: 'https://imgix.cosmicjs.com/700a2660-b036-11ee-baae-2b7553ec3713-New-Jersey.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/new-jersey" },
		{ name: 'New York', iconLink: 'https://imgix.cosmicjs.com/ec5fb110-1001-11ee-b1c4-fdbd98303cc6-New-York.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/new-york" }, 
		{ name: 'North Carolina', iconLink: 'https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg', growthleads_betting_page_path: "betting/north-carolina"},
		{ name: 'Ohio', iconLink: 'https://imgix.cosmicjs.com/ec602640-1001-11ee-b1c4-fdbd98303cc6-Ohio.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/ohio" },
		{ name: 'Pennsylvania', iconLink: 'https://imgix.cosmicjs.com/ec5e9fa0-1001-11ee-b1c4-fdbd98303cc6-Pennsylvania.svg?auto=format,compress&w=25', growthleads_betting_page_path: "betting/pennsylvania" },
		{ name: 'Vermont', iconLink: 'https://cdn.cosmicjs.com/c9813100-115a-11ee-a61c-b352d78f91a5-Vermont.svg', growthleads_betting_page_path: "betting/vermont"},
	];


    affiliateAccountDropdownData: NavigationItem = {
		type: 'user-dropdown',
		subLinks: [
			{
				type: 'link',
				title: 'My Dimers',
				subtitle: 'Your dashboard',
				href: '/my-account',
				onClick: () => this.selectTab('dashboard')
			},
			{
				type: 'link',
				title: 'Details',
				subtitle: 'Manage your account',
				href: '/my-account',
				onClick: () => this.selectTab('details')
				
			},
			{
				type: 'link',
				title: 'Subscription',
				subtitle: 'Manage your plan',
				href: '/my-account',
				onClick: () => this.selectTab('subscription')
				
			},
			{
				type: 'link',
				title: 'Preferences',
				subtitle: 'Customize your experience',
				href: '/my-account',
				onClick: () => this.selectTab('preferences')
				
			},
            {
				type: 'link',
				title: 'Affiliate Dashboard',
				subtitle: 'Your affiliate dashboard',
				href: 'https://dimers.promotekit.com/',
                strictHref: true,
				newTab: true,
			},
			{
				type: 'link',
				title: 'Log out',
				onClick: () => this.authHelper.logout({ returnTo: this.document.location.origin })
			},
		]
	}
    accountDropdownData: NavigationItem = {
		type: 'user-dropdown',
		subLinks: [
			{
				type: 'link',
				title: 'My Dimers',
				subtitle: 'Your dashboard',
				href: '/my-account',
				onClick: () => this.selectTab('dashboard')
			},
			{
				type: 'link',
				title: 'Details',
				subtitle: 'Manage your account',
				href: '/my-account',
				onClick: () => this.selectTab('details')
				
			},
			{
				type: 'link',
				title: 'Subscription',
				subtitle: 'Manage your plan',
				href: '/my-account',
				onClick: () => this.selectTab('subscription')
				
			},
			{
				type: 'link',
				title: 'Preferences',
				subtitle: 'Customize your experience',
				href: '/my-account',
				onClick: () => this.selectTab('preferences')
				
			},
			{
				type: 'link',
				title: 'Log out',
				onClick: () => this.authHelper.logout({ returnTo: this.document.location.origin })
			},
		]
	}

	sportsDropdownData: NavigationItem;
	bettingDropdownData: NavigationItem;

	propsPageOptions: Array<NavigationItem> = [];
	picksPageOptions: Array<NavigationItem> = [];
	parlayPickerPageOptions: Array<NavigationItem> = [];

   

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

    affiliate$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_affiliate
		}),
		shareReplay(1)
	);
    
	constructor(
		// private router: Router,
		public authHelper: AuthHelperService,
		private blogService: BlogService,
		// private sportDataService: SportDataService,
		public matchService: MatchService,
		@Inject(PLATFORM_ID) platformId: Object,
		@Inject(DOCUMENT) public document: Document,
	) {
		// this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnInit(): void {
		this.bettingDropdownData = {
			type: "dropdown",
			href: "/betting",
			strictHref: true,
			title: "Betting & More",
			subLinks: [
				{
					type: "dropdown",
					href: "/betting",
					strictHref: true,
					title: "Sportsbooks",
					subLinks: [
						{
							type: "link",
							title: "Sportsbook Apps",
							href: "/betting/apps",
							strictHref: true,
						},
						{
							type: "link",
							title: "New Sportsbooks",
							href: "/betting/new",
							strictHref: true,
						},
						{
							type: "link",
							title: "Best Books for NBA",
							href: "/betting/nba",
							strictHref: true,
						},
						{
							type: "link",
							title: "Best Books for NFL",
							href: "/betting/nfl",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Bet365 Review",
							iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg",
							href: "/betting/bet365-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetMGM Review",
							iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg",
							href: "/betting/betmgm-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetRivers Review",
							iconPath: "https://imgix.cosmicjs.com/98acc0b0-4861-11ee-b0ba-f39b6f0c3501-BetRivers-icon.svg",
							href: "/betting/betrivers-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "Caesars Review",
							iconPath: "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon.svg",
							href: "/betting/caesars-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "DraftKings Review",
							iconPath: "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg",
							href: "/betting/draftkings-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "ESPN Bet Review",
							iconPath: "https://imgix.cosmicjs.com/c402df60-98b1-11ee-8a8d-abf95e574482-EspnBet-small.svg",
							href: "/betting/espn-bet-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanatics Review",
							iconPath: "https://imgix.cosmicjs.com/c3e9b210-98b1-11ee-8a8d-abf95e574482-Fanatics-small.svg",
							href: "/betting/fanatics-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "FanDuel Review",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/betting/fanduel-sportsbook",
							strictHref: true,
						},
						{
							type: "link",
							title: "Hard Rock Bet Review",
							iconPath: "https://imgix.cosmicjs.com/36d8f1c0-b66a-11ee-a3dc-c10230cabb08-HardRockBet-icon.png",
							href: "/betting/hardrock-sportsbook",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					title: "Sportsbook Promos",
					href: "/betting/promo-codes",
					strictHref: true,
					subLinks: [
						{
							type: "link",
							title: "NBA Promo Codes",
							href: "/betting/nba/promo-codes",
							strictHref: true,
						},
						{
							type: "link",
							title: "NFL Promo Codes",
							href: "/betting/nfl/promo-codes",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "bet365 Promo Code",
							iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg",
							href: "/betting/bet365-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetMGM Promo Code",
							iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg",
							href: "/betting/betmgm-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Betrivers Promo Code",
							iconPath: "https://imgix.cosmicjs.com/98acc0b0-4861-11ee-b0ba-f39b6f0c3501-BetRivers-icon.svg",
							href: "/betting/betrivers-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Caesars Promo Code",
							iconPath: "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon.svg",
							href: "/betting/caesars-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanduel Promo Code",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/betting/fanduel-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Draftkings Promo Code",
							iconPath: "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg",
							href: "/betting/draftkings-sportsbook/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanatics Promo Code",
							iconPath: "https://imgix.cosmicjs.com/c3e9b210-98b1-11ee-8a8d-abf95e574482-Fanatics-small.svg",
							href: "/betting/fanatics-sportsbook/promo-code",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					title: "Sportsbooks By State",
					subLinks: [
						...this.states.map(state => ({
							type: "link" as "link",
							title: state.name,
							iconPath: state.iconLink,
							href: state.growthleads_betting_page_path,
							strictHref: true,
						})),
						{
							type: "link",
							title: "All Other States",
							iconPath: "https://cdn.cosmicjs.com/872b64b0-10bf-11ee-925e-ebfb5caceee1-United-states.svg",
							href: "/betting",
							strictHref: true,
						}
					]
				},
				{
					type: "dropdown",
					href: "/sports-betting-101",
					strictHref: true,
					title: "Sports Betting 101",
					subLinks: [
						{
							type: "link",
							title: "How To Use Dimers",
							href: "/sports-betting-101/how-to-use-dimers",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sports Betting Explained",
							href: "/sports-betting-101/sports-betting-explained",
							strictHref: true,
						},
						{
							type: "link",
							title: "State By State Guide",
							href: "/sports-betting-101/sports-betting-in-my-state",
							strictHref: true,
						},
						{
							type: "link",
							title: "How To Play Safe",
							href: "/sports-betting-101/sports-betting-scams-to-avoid",
							strictHref: true,
						},
						{
							type: "link",
							title: "Promos Explained",
							href: "/sports-betting-101/sportsbook-bonuses-and-promotions",
							strictHref: true,
						},
						{
							type: "link",
							title: "Tell Me More",
							href: "/sports-betting-101/tell-me-more-about-sports-betting",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "What Age Can I Bet On Sports",
							href: "/sports-betting-101/sports-betting-explained/what-age-can-i-bet-on-sports",
							strictHref: true,
						},
						{
							type: "link",
							title: "How To Bet On Tennis",
							href: "/sports-betting-101/sports-betting-explained/how-to-bet-on-tennis",
							strictHref: true,
						},
						{
							type: "link",
							title: "How To Bet On MLB",
							href: "/sports-betting-101/sports-betting-explained/how-to-bet-on-mlb-games",
							strictHref: true,
						},
						{
							type: "link",
							title: "How To Bet On NBA",
							href: "/sports-betting-101/sports-betting-explained/how-to-bet-on-nba-basketball-games",
							strictHref: true,
						},
						{
							type: "link",
							title: "Mobile Betting Guide",
							href: "/sports-betting-101/sports-betting-explained/what-is-mobile-sports-betting",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					href: "/betting/ca/ontario",
					strictHref: true,
					title: "Ontario Betting Sites",
					subLinks: [
						// {
						// 	type: "link",
						// 	title: "Ontario",
						// 	iconPath: "https://imgix.cosmicjs.com/1945bfe0-0c3b-11ef-911e-9f1d49670742-Ontario-white.svg?auto=compress,enhance,format&w=20",
						// 	href: "/ca/betting/ontario",
						// 	strictHref: true,
						// },
						{
							type: "link",
							title: "DraftKings Ontario",
							iconPath: "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/draftkings-canada",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetMGM Ontario",
							iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/betmgm-canada",
							strictHref: true,
						},
						{
							type: "link",
							title: "Betano Ontario",
							iconPath: "https://imgix.cosmicjs.com/dd4755a0-be68-11ee-8229-0f62eab2dd82-Betano-icon.png?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/betano",
							strictHref: true,
						},
						{
							type: "link",
							title: "Betway Ontario",
							iconPath: "https://imgix.cosmicjs.com/6b14fe80-0c36-11ef-911e-9f1d49670742-Betway-small-1.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/betway",
							strictHref: true,
						},
						// {
						// 	type: "link",
						// 	title: "Pinnacle Ontario",
						// 	iconPath: "https://imgix.cosmicjs.com/644f4a60-0c36-11ef-911e-9f1d49670742-Pinacle-small-1.svg?auto=compress,enhance,format&w=20",
						// 	href: "/ca/betting/reviews/pinnacle",
						// 	strictHref: true,
						// },
						{
							type: "link",
							title: "Bet365 Ontario",
							iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/bet365-canada",
							strictHref: true,
						},
						{
							type: "link",
							title: "PointsBet Ontario",
							iconPath: "https://imgix.cosmicjs.com/98ab8830-4861-11ee-89ab-17371fc03105-PointsBet-icon.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/pointsbet-canada",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetVictor Ontario",
							iconPath: "https://imgix.cosmicjs.com/ca8eaaf0-455c-11ef-8f56-adb81d8aea77-BetVictor-Small-White-2.svg?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/betvictor-canada",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sports Interaction Ontario",
							iconPath: "https://imgix.cosmicjs.com/20e2df50-4dc5-11ef-926f-211de7a312da-Copy-of-SILogoStackedSmallColour.png?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/sports-interaction",
							strictHref: true,
						},
						// {
						// 	type: "link",
						// 	title: "PowerPlay Ontario",
						// 	iconPath: "https://imgix.cosmicjs.com/451aba80-6339-11ef-b164-a75e20f7a9e0-PowerPlay-logo.svg?auto=compress,enhance,format&w=20",
						// 	href: "/ca/betting/reviews/powerplay-canada",
						// 	strictHref: true,
						// }
						// ,
						{
							type: "link",
							title: "888 Ontario",
							iconPath: "https://imgix.cosmicjs.com/8e281420-5e84-11ef-86e1-b75943817551-888casino-small.png?auto=compress,enhance,format&w=20",
							href: "/ca/betting/reviews/888-canada",
							strictHref: true,
						}
					]
				},
				{
					type: "dropdown",
					href: "/dfs",
					strictHref: true,
					title: "DFS Sites & Promos",
					subLinks: [
						{
							type: "link",
							title: "New DFS Sites",
							href: "/dfs/new",
							strictHref: true,
						},
						{
							type: "link",
							title: "DFS Guide",
							href: "/dfs/guide",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Dabble Fantasy",
							iconPath: "https://imgix.cosmicjs.com/643cc8a0-be6a-11ee-8229-0f62eab2dd82-Dabble-icon.png",
							href: "/dfs/dabble-fantasy",
							strictHref: true,
						},
						{
							type: "link",
							title: "Draftkings Fantasy",
							iconPath: "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png",
							href: "/dfs/draftkings-fantasy",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanduel Fantasy",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/dfs/fanduel-fantasy",
							strictHref: true,
						},
						{
							type: "link",
							title: "OwnersBox Fantasy",
							iconPath: "https://imgix.cosmicjs.com/d9f202b0-678d-11ee-bd7e-bf4f24fb39b1-OwnersBox-icon-1.png",
							href: "/dfs/ownersbox",
							strictHref: true,
						},
						{
							type: "link",
							title: "ParlayPlay",
							iconPath: "https://imgix.cosmicjs.com/065b4070-678c-11ee-bd7e-bf4f24fb39b1-ParlayPlay-icon-1.png",
							href: "/dfs/parlayplay",
							strictHref: true,
						},
						{
							type: "link",
							title: "Pick6",
							iconPath: "https://imgix.cosmicjs.com/1f76d5e0-64b3-11ef-81cc-bdfc4d6f451d-P6AppIconDev.png",
							href: "/dfs/draftkings-pick6",
							strictHref: true,
						},
						{
							type: "link",
							title: "PrizePicks",
							iconPath: "https://imgix.cosmicjs.com/a551d560-5119-11ee-a636-f9a0cdd9a170-PrizePicks-icon.png",
							href: "/dfs/prizepicks",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sleeper Fantasy",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/sleeper-fantasy-icon-56x56-1.png",
							href: "/dfs/sleeper",
							strictHref: true,
						},
						{
							type: "link",
							title: "Underdog Fantasy",
							iconPath: "https://imgix.cosmicjs.com/439556f0-c520-11ee-8a2c-19f2644627ae-Main-Logo-1.png",
							href: "/dfs/underdog-fantasy",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Draftkings DFS Promo",
							iconPath: "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png",
							href: "/dfs/draftkings-fantasy/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanduel DFS Promo",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/dfs/fanduel-fantasy/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Ownersbox DFS Promo",
							iconPath: "https://imgix.cosmicjs.com/d9f202b0-678d-11ee-bd7e-bf4f24fb39b1-OwnersBox-icon-1.png",
							href: "/dfs/ownersbox/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "ParlayPlay DFS Promo",
							iconPath: "https://imgix.cosmicjs.com/065b4070-678c-11ee-bd7e-bf4f24fb39b1-ParlayPlay-icon-1.png",
							href: "/dfs/parlayplay/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sleeper DFS Promo",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/sleeper-fantasy-icon-56x56-1.png",
							href: "/dfs/sleeper/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Underdog DFS Promo",
							iconPath: "https://imgix.cosmicjs.com/439556f0-c520-11ee-8a2c-19f2644627ae-Main-Logo-1.png",
							href: "/dfs/underdog-fantasy/promo-code",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					href: "/online-casinos",
					strictHref: true,
					title: "Online Casinos",
					subLinks: [
						{
							type: "link",
							title: "New Online Casinos",
							href: "/online-casinos/new",
							strictHref: true,
						},
						{
							type: "link",
							title: "Online Casinos With Fast Payout",
							href: "/online-casinos/fast-payout",
							strictHref: true,
						},
						{
							type: "link",
							title: "Minimum Deposit Casinos",
							href: "/online-casinos/minimum-deposit",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Bet365 Casino",
							iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg",
							href: "/online-casinos/bet365-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetMGM Casino",
							iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg",
							href: "/online-casinos/betmgm-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Borgata Casino",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/borgata-casino-icon-56x56-1.png",
							href: "/online-casinos/borgata-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Caesars Casino",
							iconPath: "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon.svg",
							href: "/online-casinos/caesars-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Draftkings Casino",
							iconPath: "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png",
							href: "/online-casinos/draftkings-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanduel Casino",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/online-casinos/fanduel-casino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Playstar Casino",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/playstar-icon-56x56-1.png",
							href: "/online-casinos/playstar-casino",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					href: "/online-casinos/bonus",
					strictHref: true,
					title: "Online Casino Bonuses",
					subLinks: [
						{
							type: "link",
							title: "Online Casino Bonus",
							href: "/online-casinos/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Online Casino No Deposit Bonus",
							href: "/online-casinos/bonus/no-deposit",
							strictHref: true,
						},
						{
							type: "link",
							title: "Bet365 Casino Bonus",
							iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg",
							href: "/online-casinos/bet365-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "BetMGM Casino Bonus",
							iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg",
							href: "/online-casinos/betmgm-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Borgata Casino Bonus",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/borgata-casino-icon-56x56-1.png",
							href: "/online-casinos/borgata-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Caesars Casino Bonus",
							iconPath: "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon.svg",
							href: "/online-casinos/caesars-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Draftkings Casino Bonus",
							iconPath: "https://imgix.cosmicjs.com/d8d58320-7a92-11ef-9e22-210e6365981c-draftkings-icon-light.png",
							href: "/online-casinos/draftkings-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fanduel Casino Bonus",
							iconPath: "https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg",
							href: "/online-casinos/fanduel-casino/bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Playstar Casino Bonus",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/playstar-icon-56x56-1.png",
							href: "/online-casinos/playstar-casino/bonus",
							strictHref: true,
						},
					]
				},
				{
					type: "dropdown",
					title: "Sweepstakes Casinos",
					href: "/sweepstakes-casinos",
					strictHref: true,
					subLinks: [
						{
							type: "link",
							title: "New Sweepstakes Casinos",
							href: "/sweepstakes-casinos/new",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweepstakes Poker Sites",
							href: "/sweepstakes-casinos/poker",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweepstakes Casino Guide",
							href: "/sweepstakes-casinos/guide",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweepstakes Casino Sites Like",
							href: "/sweepstakes-casinos/sites-like",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Fortune Coins Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/fortune-coins-icon-56x56-1.png",
							href: "/sweepstakes-casinos/fortune-coins",
							strictHref: true,
						},
						{
							type: "link",
							title: "Hello Millions Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/hello-millions-icon-56x56-1.png",
							href: "/sweepstakes-casinos/hello-millions",
							strictHref: true,
						},
						{
							type: "link",
							title: "High 5 Casino Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/high-5-casino-icon-56x56-1.png",
							href: "/sweepstakes-casinos/high-5",
							strictHref: true,
						},
						{
							type: "link",
							title: "McLuck Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/McLuck-icon-56x56-1.png",
							href: "/sweepstakes-casinos/mcluck",
							strictHref: true,
						},
						{
							type: "link",
							title: "MegaBonanza Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/mega-bonanza-icon-56x56-1.png",
							href: "/sweepstakes-casinos/megabonanza",
							strictHref: true,
						},
						{
							type: "link",
							title: "Pulsz Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/pulsz-icon-56x56-1.png",
							href: "/sweepstakes-casinos/pulsz",
							strictHref: true,
						},
						{
							type: "link",
							title: "RealPrize Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/realprize-icon-56x56-1.png",
							href: "/sweepstakes-casinos/realprize-com",
							strictHref: true,
						},
						{
							type: "link",
							title: "Stake.us Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/stake-icon-56x56-1.png",
							href: "/sweepstakes-casinos/stake-us",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweeptastic Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/sweeptastic-icon-56x56-1.png",
							href: "/sweepstakes-casinos/sweeptastic",
							strictHref: true,
						},
						{
							type: "link",
							title: "Wow Vegas Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/wow-vegas-icon-56x56-1.png",
							href: "/sweepstakes-casinos/wow-vegas",
							strictHref: true,
						},
						{
							type: "link",
							title: "Zula Casino Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/zula-casino-icon-56x56-1.png",
							href: "/sweepstakes-casinos/zula-casino",
							strictHref: true,
						},
					],
				},
				{
					type: "dropdown",
					title: "Sweepstakes Casino Promo Codes",
					href: "/sweepstakes-casinos/promo-code",
					strictHref: true,
					subLinks: [
						{
							type: "link",
							title: "Sweepstakes Casino Free Spins",
							href: "/sweepstakes-casinos/promo-code/free-spins",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweepstakes Casino No Deposit Bonus",
							href: "/sweepstakes-casinos/promo-code/no-deposit-bonus",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweepstakes Loot & Offers",
							href: "/sweepstakes-casinos/loot",
							strictHref: true,
							followedByDivider: true,
						},
						{
							type: "link",
							title: "Fortune Coins Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/fortune-coins-icon-56x56-1.png",
							href: "/sweepstakes-casinos/fortune-coins/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Hello Millions Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/hello-millions-icon-56x56-1.png",
							href: "/sweepstakes-casinos/hello-millions/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "High 5 Casino Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/high-5-casino-icon-56x56-1.png",
							href: "/sweepstakes-casinos/high-5/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "McLuck Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/McLuck-icon-56x56-1.png",
							href: "/sweepstakes-casinos/mcluck/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "MegaBonanza Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/mega-bonanza-icon-56x56-1.png",
							href: "/sweepstakes-casinos/megabonanza",
							strictHref: true,
						},
						{
							type: "link",
							title: "Pulsz Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/pulsz-icon-56x56-1.png",
							href: "/sweepstakes-casinos/pulsz/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "RealPrize Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/realprize-icon-56x56-1.png",
							href: "/sweepstakes-casinos/realprize-com",
							strictHref: true,
						},
						{
							type: "link",
							title: "Stake.us Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/stake-icon-56x56-1.png",
							href: "/sweepstakes-casinos/stake-us/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sweeptastic Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/sweeptastic-icon-56x56-1.png",
							href: "/sweepstakes-casinos/sweeptastic/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Wow Vegas Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/wow-vegas-icon-56x56-1.png",
							href: "/sweepstakes-casinos/wow-vegas/promo-code",
							strictHref: true,
						},
						{
							type: "link",
							title: "Zula Casino Promo Code",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2024/12/zula-casino-icon-56x56-1.png",
							href: "/sweepstakes-casinos/zula-casino/promo-code",
							strictHref: true,
						},
					],
				},
				{
					type: "dropdown",
					title: "Social Sportsbooks",
					href: "/social-sportsbooks",
					strictHref: true,
					subLinks: [
						{
							type: "link",
							title: "Sportzino Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/sportzino-icon-56x56-1.png",
							href: "social-sportsbooks/sportzino",
							strictHref: true,
						},
						{
							type: "link",
							title: "Thrillzz Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/thrillzz-icon-56x56-1.png",
							href: "social-sportsbooks/thrillzz",
							strictHref: true,
						},
						{
							type: "link",
							title: "Fliff Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/fliff-icon-56x56-1.png",
							href: "social-sportsbooks/fliff",
							strictHref: true,
						},
						{
							type: "link",
							title: "Juked Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/juked-icon-56x56-1.png",
							href: "social-sportsbooks/juked",
							strictHref: true,
						},
						{
							type: "link",
							title: "Onyx Odds Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/onyx-icon-56x56-1.png",
							href: "social-sportsbooks/onyx-odds",
							strictHref: true,
						},
						{
							type: "link",
							title: "Get Prophet X Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/prophet-x-icon-56x56-1.png",
							href: "social-sportsbooks/get-prophet-x",
							strictHref: true,
						},
						{
							type: "link",
							title: "Sportsmillions Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/sportsmillions-icon-56x56-1.png",
							href: "social-sportsbooks/sportsmillions",
							strictHref: true,
						},
						{
							type: "link",
							title: "Novig Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/novig-icon-56x56-1.png",
							href: "social-sportsbooks/novig",
							strictHref: true,
						},
						{
							type: "link",
							title: "Legendz Casino Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/legendz-icon-56x56-1.png",
							href: "social-sportsbooks/legendz",
							strictHref: true,
						},
						{
							type: "link",
							title: "Kickr Review",
							iconPath: "/sweepstakes-casinos/wp-content/uploads/2025/01/kickr-icon-56x56-1.png",
							href: "social-sportsbooks/kickr",
							strictHref: true,
						},
					]
				}

				
			]
		};

		this.sportsDropdownData = {
			type: "dropdown",
			title: "Sports",
			href: "/sports",
			subLinks: [
				...this.dropdownSports.map(sport => ({
					type: "dropdown" as "dropdown",
					title: sport.shortName,
					href: sport.navigablePages[0]?.path || undefined,
					queryParams: sport.navigablePages[0]?.queryParams || undefined,
					subLinks: sport.navigablePages.map(page => ({
						type: "link" as "link",
						href: page.path,
						queryParams: page.queryParams,
						title: page.name,
					}))
				})),
				{
					type: "dropdown",
					title: "Other Sports",
					subLinks: this.otherSports.map(sport => ({
						type: "link",
						href: sport.navigablePages[0].path,
						queryParams: sport.navigablePages[0].queryParams,
						title: sport.shortName,
					}))
				}
			]
		}

		this.propsPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/prop-bets"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "best-props")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("best-props")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("best-props")).queryParams,
			}))
		]

		this.picksPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/best-bets"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "best-bets")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("best-bets")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("best-bets")).queryParams,
			}))
		]

		this.parlayPickerPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/parlay-picker"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "parlay-picker")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("parlay-picker")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("parlay-picker")).queryParams,
			}))
		]
	}

	
	// closeSearch(): void {
	// 	this.searchBarActive = false;
	// 	this.searchText = "";
	// }
	
	// advanceSearch(): void {
	// 	if (!this.searchBarActive) {
	// 		this.searchBarActive = true;
	// 	} else if (this.searchText) {
	// 		this.submitSearch();
	// 	}
	// }
	
	// submitSearch(): void {
	// 	this.router.navigateByUrl("/search?q=" + this.searchText)
	// }

    selectTab(tab: string) {
        this.blogService.setSelectedMyaccountTab(tab);
    }

    openProModal() {
        // Open Pro modal
        this.blogService.setProModalData(true);
    }
    // openTagsModal() {
    //     this.blogService.setTagsNotificationModalData(true);
    //     sessionStorage.setItem('hasClickTagsPopup', 'true');
    //     this.dotVisible = false;
    // }
}