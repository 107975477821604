import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pro-review-carousel',
  standalone: true,
  imports: [
    FontAwesomeModule,
    NgFor,
  ],
  templateUrl: './pro-review-carousel.component.html',
  styleUrl: './pro-review-carousel.component.scss'
})
export class ProReviewCarouselComponent {
  faStar = faStar;

  
  animated_carousel = [
    "“I love this site, they not only give you a fish, but they teach you how to fish”",
    "“Dimers feels like having a secret weapon”", 
    "“Dimers turned me from a newbie to a pro literally overnight”",
    "“I don't go a day without checking Dimers”",
    "“This site is a game-changer you didn't know you needed”",
  ]
}
