<div class="modal-overlay fixed w-full h-full top-0 left-0 z-[999] no-doc-scroll" *ngIf="visible"> 
    <div class="backdrop-blur-sm bg-black/50 w-full h-full" (click)="setVisible()">
	</div>
    <div class="modal-dialog flex max-h-[95%] w-full md:w-[720px] lg:w-[800px] bottom-0 md:bottom-auto md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 absolute">
        <div class="flex close-button leading-none -top-9 right-2 md:right-0 cursor-pointer absolute h-[28px] min-w-[28px] bg-white rounded-full items-center justify-center" (click)="setVisible()">
            <fa-icon class="text-black text-opacity-60 text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
        <div class="rounded-t-3xl md:rounded-3xl overflow-auto max-md:w-full grid grid-cols-1 md:grid-cols-2"  *ngIf="authHelper.userRetrievalDone && !authHelper.user || (proUser$ | async) === true ">
            <div class="primary-panel bg-white text-black p-6 md:p-6 lg:p-7 flex-1 flex flex-col justify-center items-stretch gap-5 md:gap-4 max-w-full aspect-[1240 / 211]">
                <app-pro-pricing-panel toggleCategory="modal"></app-pro-pricing-panel>
                <div class="login-text text-AIDimers-blackLight cursor-pointer text-center block sm:hidden"
                    *ngIf="authHelper.userRetrievalDone && !authHelper.user"
                    (click)="authHelper.goToLoginWithState()">
                    Already have an account? 
                    <span class="underline text-highlight-100">Log in</span>
                </div>
            </div>
            <div class="secondary-panel bg-AIDimers-grey text-black p-8 md:p-6 lg:p-8 flex-1 hidden md:flex flex-col justify-center items-stretch">
                <div class="hidden sm:block text-lg text-highlight-100 font-semibold mb-2">
                    What you’ll get
                </div>
                <app-pro-comparison-table></app-pro-comparison-table>
                <div class="login-text text-AIDimers-blackLight cursor-pointer text-left mt-4 hidden sm:block"
                    *ngIf="authHelper.userRetrievalDone && !authHelper.user"
                    (click)="authHelper.goToLoginWithState()">
                    Already have an account? 
                    <span class="underline text-highlight-100">Log in</span>
                </div>
            </div>
            <app-pro-review-carousel></app-pro-review-carousel>
        </div>
        <ng-container  *ngIf="(proUser$ | async) === false && (authHelper.userRetrievalDone && authHelper.user)">
            <div class="grid grid-cols-1 md:grid-cols-2 rounded-t-xl md:rounded-3xl shadow overflow-auto bg-white">
                <div class="primary-panel bg-white text-black p-6 lg:p-7 flex-1 flex flex-col justify-center items-stretch gap-4 lg:gap-6 max-w-full aspect-[1240 / 211]">
                    <app-pro-pricing-panel toggleCategory="modal"></app-pro-pricing-panel>
                </div>
                <div class="secondary-panel bg-AIDimers-grey text-black p-8 md:p-6 lg:p-7 flex-1 hidden md:flex flex-col justify-center items-stretch">
                    <div class="hidden sm:block text-lg text-highlight-100 font-semibold mb-2">
                        What you’ll get
                    </div>
                    <app-pro-comparison-table></app-pro-comparison-table>
                </div>
                <app-pro-review-carousel></app-pro-review-carousel>
            </div>
        </ng-container>
    </div>
</div>