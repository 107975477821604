<div class="bottom-carousel col-span-2 bg-AIDimers-grey">
	<div class="hidden sm:flex logo-slider p-2.5">
		<div class="flex items-center">
				<div class="block-size" *ngFor="let data of animated_carousel; let i = index">
					<div class="flex items-center gap-2 text-gray-600 text-sm font-medium whitespace-nowrap ">
						<!-- <img alt="pro-logo" [src]="data.small_icon.imgix_url + '?auto=format,compress&h=36'" loading="lazy" class="h-6 w-6" *ngIf="data?.small_icon?.imgix_url" height="24" width="24"> -->
						<div>
							<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
							<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
							<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
							<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
							<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
						</div>
						{{ data }}
					</div>
				</div>
		</div>
		<div class="flex items-center">
			<div class="block-size" *ngFor="let data of animated_carousel; let i = index">
				<div class="flex items-center gap-2 text-gray-600 text-sm font-medium whitespace-nowrap ">
					<div>
						<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
						<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
						<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
						<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
						<fa-icon [icon]="faStar" class="text-yellow-500" ></fa-icon>
					</div>
					{{ data }}
				</div>
			</div>
		</div>
	</div>
</div>