<table class="table-auto md:table-fixed shadow rounded-2xl p-4 overflow-hidden bg-AIDimers-grey w-full">
    <thead>
        <tr>
            <th class="text-sm font-bold">Features</th>
            <th class="text-sm font-bold">Basic</th>
            <th class="text-sm font-bold">
                <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="inline"
                    loading="lazy" width="24" height="24" />
                Pro
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Best Bets
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                3&nbsp;x&nbsp;day
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                Unlimited
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Best Props
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                3&nbsp;x&nbsp;day
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                Unlimited
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Parlays
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                1&nbsp;x&nbsp;day
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                Unlimited
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Trends
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                3&nbsp;x&nbsp;day
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                Unlimited
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Favorite games
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Bet filters
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/19358d70-d20a-11ee-babb-b99b48a02ac8-cross-red.svg" />
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
            </td>
        </tr>
        <tr>
            <td class="text-gray-800 text-sm font-medium">
                Discord
            </td>
            <td class="text-gray-800 text-sm font-medium bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/19358d70-d20a-11ee-babb-b99b48a02ac8-cross-red.svg" />
            </td>
            <td class="text-highlight-100 text-sm font-semibold bg-white">
                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
            </td>
        </tr>
    </tbody>
</table>