import { Component } from '@angular/core';

@Component({
  selector: 'app-pro-comparison-table',
  standalone: true,
  imports: [],
  templateUrl: './pro-comparison-table.component.html',
  styleUrl: './pro-comparison-table.component.scss'
})
export class ProComparisonTableComponent {

}
