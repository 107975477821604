import { NgFor, NgIf, NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { ProComparisonTableComponent } from '../pro-comparison-table/pro-comparison-table.component';

@Component({
  selector: 'app-pro-pricing-panel',
  standalone: true,
  imports: [
    NgIf,
    TitleCasePipe,
    FontAwesomeModule,
    RouterLink,
    NgFor,
    ProComparisonTableComponent,
    NgOptimizedImage,
  ],
  templateUrl: './pro-pricing-panel.component.html',
  styleUrl: './pro-pricing-panel.component.scss'
})
export class ProPricingPanelComponent {

  @Input({required: true}) toggleCategory: string;
  
  faShieldCheck = faShieldCheck;

  frequencyOptions = ["weekly", "monthly", "yearly"];
  selectedFrequency:string = "monthly";

  constructor(
    private gtmService: GoogleTagManagerService,
  ) {}

  setFrequency(freq: string) {
    this.selectedFrequency = freq;
    const gtmTag = {
        event: "pro_plan_toggle",
        event_category: "modal",
        event_label: freq,
    };

    this.gtmService.pushTag(gtmTag)
        .catch(e => {
            console.error("Google Tag Manager Script failed: " + e)
        });
  }
}
