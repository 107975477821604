import { NgModule, PLATFORM_ID, inject } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';

import { ErrorPageComponent } from './general/error-page/error-page.component';
import { SearchResultsPageComponent } from './general/search-results-page/search-results-page.component';
import { CallbackPageComponent } from './general/callback-page/callback-page.component';

import { LandingPageGuard } from "./landing-page.guard";
import { PromoRedirectGuard } from "./promo-redirect.guard";
import { ProBuyLinkGuard } from "./pro-buy-link.guard";
import { EmailVerifyRequiredPageComponent } from './general/email-verify-required-page/email-verify-required-page.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { PaymentCancelPageComponent } from './general/payment-cancel-page/payment-cancel-page.component';
// import { CosmicSportsbooksComponent } from './bookmakers/cosmic-sportsbooks/cosmic-sportsbooks.component';
// import { CaSportsbooksReviewComponent } from './bookmakers/ca-sportsbooks-review/ca-sportsbooks-review.component';
import { isPlatformBrowser } from '@angular/common';
import { AuthSignUpGuard } from "./auth-sign-up.guard";
const routes: Routes = [


	// Hard-coded redirects copied from Dimers CMS "App Redirects" list
	{path: "news/trends-from-the-last-10-super-bowls-2411", redirectTo: "sports-betting-101/tell-me-more-about-sports-betting/the-definitive-list-of-super-bowl-trends-since-2010"},
	{path: "news/the-biggest-upsets-in-super-bowl-history-2419", redirectTo: "sports-betting-101/tell-me-more-about-sports-betting/the-biggest-upsets-in-super-bowl-history"},
	{path: "news/history-of-super-bowl-mvp-winners-2407", redirectTo: "sports-betting-101/tell-me-more-about-sports-betting/the-definitive-list-of-super-bowl-mvp-award-winners"},
	{path: "news/history-of-super-bowl-first-touchdown-scorers-2404", redirectTo: "sports-betting-101/tell-me-more-about-sports-betting/the-definitive-list-of-super-bowl-first-touchdown-scorers"},
	{path: "news/best-super-bowl-lv-prop-plays-2420", redirectTo: "sports-betting-101/tell-me-more-about-sports-betting/the-best-super-bowl-prop-bets"},
	{path: "news/beginners-guide-to-live-betting-on-super-bowl-lv-55-2405", redirectTo: "sports-betting-101/sports-betting-explained/beginners-guide-to-super-bowl-live-betting"},
	{path: "news/beginners-guide-to-betting-on-super-bowl-lv-55-2403", redirectTo: "sports-betting-101/sports-betting-explained/beginners-guide-to-betting-on-the-super-bowl"},
	{path: "news/2410/best-super-bowl-betting-strategies", redirectTo: "sports-betting-101/sports-betting-explained/the-best-super-bowl-betting-strategies"},
	{path: "news/steelers-vs-browns-preview-betting-picks-predictions-and-odds-5128", redirectTo: "news/steelers-vs-browns-betting-picks-predictions-and-odds-sun-oct-31-2021-5371"},
	{path: "dimers-unibet", redirectTo: "unibet"},
	{path: "dimers-caesars-sportsbook", redirectTo: "caesars-sportsbook"},
	// {path: "best-odds?league=liga-mx&val=SOX", redirectTo: "best-odds?league=lmx&val=SOX"},
	{path: "news/nfl-most-likely-touchdown-scorers-week-11-1696", redirectTo: "news/nfl-week-11-2021-most-likely-touchdown-scorers-4154"},
	{path: "news/nfl-most-likely-touchdown-scorers-week-12-1772", redirectTo: "news/nfl-week-12-2021-most-likely-touchdown-scorers-4156"},
	{path: "news/the-house-edge-podcast/", redirectTo: "house-edge-podcast"},
	{path: "news/-the-house-edge-podcast", redirectTo: "house-edge-podcast"},
	{path: "news/the-house-edge-podcast/", redirectTo: "house-edge-podcast"},
	{path: "nhl-hub", redirectTo: "bet-hub/nhl/schedule"},
	{path: "nfl-hub", redirectTo: "bet-hub/nfl/schedule"},
	{path: "epl-hub", redirectTo: "bet-hub/epl/schedule"},
	{path: "cfb-hub", redirectTo: "bet-hub/cfb/schedule"},
	{path: "news/nfl-week-1-best-sportsbook-promotions-to-bet-1001", redirectTo: "news/best-2021-nfl-sportsbook-promotions-4108"},
	{path: "news/the-house-edge-podcast", redirectTo: "house-edge-podcast"},
	{path: "news/-the-house-edge-podcast", redirectTo: "house-edge-podcast"},
	{path: "news/-props-and-hops-podcast-3097", redirectTo: "props-and-hops-podcast"},
	{path: "news/-introducing-the-tail-and-print-golf-podcast-3095", redirectTo: "tail-and-print-golf-podcast"},
	{path: "bet-hub/cbf/schedule", redirectTo: "bet-hub/cfb/schedule"},
	{path: "news/the-house-edge-podcast", redirectTo: "house-edge-podcast"},
	{path: "news/introducing-the-tail-and-print-golf-podcast-3095", redirectTo: "tail-and-print-golf-podcast"},
	{path: "news/props-and-hops-podcast-3097", redirectTo: "props-and-hops-podcast"},
	{path: "news/how-to-use-dimers-bet-hub-918", redirectTo: "sports-betting-101/how-to-use-the-dimers-website/how-to-use-dimers-bet-hub"},
	{path: "news/nfl-most-likely-touchdown-scorers-week-10-1640", redirectTo: "news/nfl-week-10-2021-most-likely-touchdown-scorers-3992"},
	{path: "news/nfl-most-likely-touchdown-scorers-week-9-1576", redirectTo: "news/nfl-week-9-2021-most-likely-touchdown-scorers-3991"},
	{path: "news/nfl-most-likely-touchdown-scorers-week-8-1516", redirectTo: "news/nfl-week-8-2021-most-likely-touchdown-scorers-3970"},
	{path: "news/most-likely-touchdown-scorers-nfl-week-7-1448", redirectTo: "news/nfl-week-7-2021-most-likely-touchdown-scorers-3969"},
	{path: "news/in-for-six-most-likely-touchdown-scorers-nfl-week-6-1394", redirectTo: "news/nfl-week-6-2021-most-likely-touchdown-scorers-3919"},
	{path: "news/nfl-2020-week-5-most-likely-touchdown-scorers-1335", redirectTo: "news/nfl-week-5-2021-most-likely-touchdown-scorers-3918"},
	{path: "news/nfl-2020-week-4-most-likely-touchdown-scorers-1284", redirectTo: "news/nfl-week-4-2021-most-likely-touchdown-scorers-3917"},
	{path: "news/nfl-2020-week-3-most-likely-touchdown-scorers-1231", redirectTo: "news/nfl-week-3-2021-most-likely-touchdown-scorers-3916"},
	{path: "news/nfl-2020-week-2-most-likely-touchdown-scorers-1129", redirectTo: "news/nfl-week-2-2021-most-likely-touchdown-scorers-3873"},
	{path: "my-locker", redirectTo: "account/my-locker"},
	{path: "news/nfl-2020-week-1-most-likely-touchdown-scorers-1034", redirectTo: "news/nfl-week-1-2021-most-likely-touchdown-scorers-3813"},
	{path: "news/super-bowl-lv-squares-tampa-bay-buccaneers-vs-kansas-city-chiefs-2429", redirectTo: "super-bowl-squares"},
	{path: "news/super-bowl-lv-squares-play-at-home-2444", redirectTo: "super-bowl-squares"},
	{path: "news/best-2021-nfl-sportsbook-promotions-4108", redirectTo: "news/best-nfl-super-bowl-lvi-sportsbook-promotions-and-boosts-4108"},
	{path: "news/best-nfl-week-18-sportsbook-promotions-and-boosts-4108", redirectTo: "news/best-nfl-super-bowl-lvi-sportsbook-promotions-and-boosts-4108"},
	{path: "dimers-pro-cbb-tournament-2022-pricing", redirectTo: "dimers-pro-pricing"},
	{path: "dimers-pro-cbb-tournament-2022", redirectTo: "dimers-pro"},
	{path: "super-bowl-squares", redirectTo: "super-bowl/squares"},

	{path: "pro-purchase/:rate", canActivate: [() => isPlatformBrowser(inject(PLATFORM_ID)), AuthGuard, ProBuyLinkGuard], children: []},
    {path: "pro-purchase-campaign", canActivate: [() => isPlatformBrowser(inject(PLATFORM_ID)), AuthSignUpGuard, ProBuyLinkGuard], children: []},
	{path: "pro-purchase-special-offer", canActivate: [() => isPlatformBrowser(inject(PLATFORM_ID)), AuthGuard, ProBuyLinkGuard], children: []},

	{path: "news", loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)},
	{path: "sports/picks", loadChildren: () => import('./picks/picks.module').then(m => m.PicksModule), data: { breadcrumb: "Picks"}},
	{path: ":sport/picks", loadChildren: () => import('./picks/picks.module').then(m => m.PicksModule)},
	// {path: "todays-promos", loadChildren: () => import('./promo-articles/promo-articles.module').then(m => m.PromoArticlesModule), data: { breadcrumb: "Today's Promos"}},
	{path: "articles", redirectTo: "news"},
	{path: "callback", component: CallbackPageComponent},
	{path: "email-verify-required", component: EmailVerifyRequiredPageComponent},
	{path: "payment-cancel", component: PaymentCancelPageComponent},
	{path: "futures/:sport", loadChildren: () => import('./futures/futures.module').then(m => m.FuturesModule)},
	{path: "parlay-picker", loadChildren: () => import('./parlay-picker/parlay-picker.module').then(m => m.ParlayPickerModule), data: { breadcrumb: 'Parlay Picker'}},
	{path: "bet-hub/swc/preview", loadChildren: () => import('./swc-preview/swc-preview.module').then(m => m.SwcPreviewModule)},
	// {path: "super-bowl", loadChildren: () => import('./nfl/nfl.module').then(m => m.NflModule)},
	{path: "march-madness", loadChildren: () => import('./cbb/cbb.module').then(m => m.CbbModule)},
	// {path: ":sport/ladder", redirectTo: ":sport/futures"},
	// {path: ":sport/sackometer", loadChildren: () => import('./sackometer/sackometer.module').then(m => m.SackometerModule)},
	// {path: "afl/brownlow", loadChildren: () => import('./brownlow/brownlow.module').then(m => m.BrownlowModule)},
	{path: "cbb/simulator", redirectTo: "march-madness/simulator"},
	{path: ":sport/simulator", loadChildren: () => import('./simulator/simulator.module').then(m => m.SimulatorModule)},
	// {path: "simulator", redirectTo: "swc/simulator"},
	{path: "bet-hub/golf", loadChildren: () => import('./golf/golf.module').then(m => m.GolfModule)},
	{path: "bet-hub/:sport/schedule/:matchid", loadChildren: () => import('./match-centre/match-centre.module').then(m => m.MatchCentreModule)},
	{path: "live-now", loadChildren: () => import('./live/live.module').then(m => m.LiveModule), data: { breadcrumb: 'Live Now'}},
	{path: "bet-hub/:sport/schedule", loadChildren: () => import('./fixtures/fixtures.module').then(m => m.FixturesModule)},
	{path: "best-trends/:sport", loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule)},
	// {path: ":sport/articles", redirectTo: "news"},
	{path: "quick-picks", redirectTo: "best-bets"},
	{path: "best-bets", loadChildren: () => import('./value-summary/value-summary.module').then(m => m.ValueSummaryModule),data: { breadcrumb: 'Best Bets'}},
	{path: "live-bets", loadChildren: () => import('./live-bets/live-bets.module').then(m => m.LiveBetsModule)},
	{path: "prop-bets", loadChildren: () => import('./value-summary-props/value-summary-props.module').then(m => m.ValueSummaryPropsModule),data: { breadcrumb: 'Prop Bets'}},
	{path: "best-props", loadChildren: () => import('./value-summary-props/value-summary-props.module').then(m => m.ValueSummaryPropsModule)},
	{path: "podcasts", loadChildren: () => import('./podcasts/podcasts.module').then(m => m.PodcastsModule), data: { breadcrumb: 'Podcasts'}},
	{path: "news-letter", loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule)},
	{path: "newsletter", redirectTo: "news-letter"},
	// {path: "sports-betting-101", loadChildren: () => import('./sports-betting-101/sports-betting-101.module').then(m => m.SportsBetting101Module), data: { breadcrumb: 'Sports Betting 101'}},
	{path: "free-to-play", loadChildren: () => import('./free-to-play/free-to-play.module').then(m => m.FreeToPlayModule)},
	// {path: "club20", loadChildren: () => import('./club20/club20.module').then(m => m.Club20Module)},
	// {path: "raceday", redirectTo: "racing/quickviewform"},
	// {path: "racecheatsheet", redirectTo: "racing/cheatsheet"},
	// {path: "raceschedule", redirectTo: "racing/schedule"},
	// {path: "racepage", redirectTo: "racing/race"},
	{path: "my-account", loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
	{path: "pro", loadChildren: () => import('./pro/pro.module').then(m => m.ProModule)},
    {path: "payment-success",  loadChildren: () =>import('./payment/payment.module').then(m=>m.PaymentModule)},
	{path: "sports", loadComponent: () => import('./sport-list/sport-list-page/sport-list-page.component').then(c => c.SportListPageComponent)},

	{path: "contact-us", loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)},
	{path: "about-us", loadChildren: () => import('./about/about.module').then(m => m.AboutModule)},
    // {path: "our-data", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
    // {path: "cos", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
    // {path: "professor", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
    // {path: "privacy-policy", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
	// {path: "terms-of-service", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
	// {path: "editorial-guidelines", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
	// {path: "responsible-gambling", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},

    {path: "business", loadChildren: () => import('./dimers-business/dimers-business.module').then(m => m.DimersBusinessModule)},
    {path: "subscription", loadChildren: () =>import('./ai-dimers/ai-dimers.module').then(m=>m.AiDimersModule)},
	// {path: "the-data", redirectTo: "help/the-data"},
	// {path: "punting-info", redirectTo: "help/punting-info"},
	// {path: "jobs", redirectTo: "help/jobs"},
	{path: "search", component: SearchResultsPageComponent},
	{path: "bet-hub/ten/rankings", loadChildren: () => import('./tennis/tennis.module').then(m => m.TennisModule)},
	// {path: "ten/results", redirectTo: "ten/round"},
	{path: "", pathMatch: "full", loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),data: { breadcrumb: 'Home'}},
	{path: "home", redirectTo: "/"},
    {path: "notfound", component: ErrorPageComponent},
	// {path: "best-books", loadChildren: () => import('./bookmakers/bookmakers.module').then(m => m.BookmakersModule), data: { breadcrumb: {alias: 'bestBooks'}}},
	// {path: "ca/betting/ontario", loadComponent: () => import('./bookmakers/cosmic-sportsbooks/cosmic-sportsbooks.component').then(c => c.CosmicSportsbooksComponent)},
    // {path: "ca/betting/reviews/:sportsbook", loadComponent: () => import('./bookmakers/ca-sportsbooks-review/ca-sportsbooks-review.component').then(c => c.CaSportsbooksReviewComponent)},
    // {path: "dfs", loadChildren: () => import('./dfs/dfs.module').then(m => m.DfsModule)},
	{path: "best-odds", loadChildren: () => import('./best-odds/best-odds.module').then(m => m.BestOddsModule)},
	{path: ":sport/best-odds", loadChildren: () => import('./best-odds/best-odds.module').then(m => m.BestOddsModule)},
	// {path: "bet-center", loadChildren: () => import('./my-bets/my-bets.module').then(m => m.MyBetsModule), data: { breadcrumb: 'Bet Center'}},
	{path: "sitemap", loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule)},
	{path: "go/:param1", canActivate: [PromoRedirectGuard], children: []},
	{path: "go/:param1/:param2", canActivate: [PromoRedirectGuard], children: []},
	{path: "go/:param1/:param2/:param3", canActivate: [PromoRedirectGuard], children: []},
	// {path: "preview/:slug", redirectTo: "news/preview/:slug", pathMatch: "full"},
    {path: "streaming/:pagecode",loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule), data: {specialRule: "streaming"}},
    // {path: "super-bowl",loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule)},
    {path: "super-bowl/:pagecode",loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule), data: {specialRule: "super-bowl"}},
	{path: ":pagecode", loadChildren: () => import('./landing-page-with-offer/landing-page-with-offer.module').then(m => m.LandingPageWithOfferModule),canActivate: [LandingPageGuard]},
	{path: "**", component: ErrorPageComponent},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled',
})],
	exports: [RouterModule]
})
export class AppRoutingModule { }